.timeline {
    position: relative;
    /* padding-right: 30px; */
    height: 100%;
}

.timeline::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -15px;
    width: 2px;
    background: #000000;
}

.timeline-item {
    position: relative;
    margin-bottom: 80px;
    text-align: right;
}

.timeline-item::before {
    content: "";
    position: absolute;
    right: -8.5px;
    top: 45px;
    width: 20px;
    height: 2px;
    background: #000000;
}

.timeline-content {
    padding: 5px 0;
}

.week-date {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.week {
    font-weight: bold;
    font-size: 1.1em;
}

.date {
    color: #888;
}

.metrics {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.metric {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.metric-name {
    font-weight: bold;
}

.metric-value {
    display: flex;
    align-items: center;
    gap: 5px;
}

.increase {
    color: rgb(63 122 236);
}

.decrease {
    color: rgb(255 0 0);
}

.last-item {
    margin-bottom: 0;
}
